(function ($) {

  // const $scrollboosters = $('[data-scrollbooster]');
  //
  // $scrollboosters.each(function () {
  //   const $this = $(this);
  //   const mode = $this.data('scrollbooster-mode') || 'x';
  //   const until = $this.data('scrollbooster-until');
  //   const content = $this.children()[0];
  //   console.log(content)
  //
  //   const sb = new ScrollBooster({
  //     viewport: $this[0],
  //     content,
  //     bounce: true,
  //     emulateScroll: true,
  //     onUpdate: (state) => {
  //       content.style.transform = `translateX(${-state.position.x}px)`;
  //     },
  //     shouldScroll: (state, event) => until ? window.innerWidth < until : true,
  //     onClick: (state, event, isTouchDevice) => {
  //       if (Math.abs(data.dragOffset.x) > 5) {
  //         e.preventDefault();
  //       }
  //     }
  //   });
  // })

  let menuBtn = document.querySelector('header .btn-menu')
  let menuList = document.querySelector('header .main-nav')
  let scrollBlock = document.querySelector('html')
  if (menuList) {
    document.addEventListener('click', function (e) {
      let target = e.target
      if (target == menuBtn) {
        if (menuList.classList.contains('opened')) {
          menuList.classList.remove('opened')
        } else {
          menuList.classList.add('opened')
        }
      } else if (!e.target.closest('.main-nav') || e.target.classList.contains('main-nav')) {
        menuList.classList.remove('opened')
      }
    })
  }

  let search = document.querySelector('.header .search')
  if (search) {
    search.addEventListener('click', function () {
      let searchWindow = document.querySelector('.fw-search')
      if (searchWindow) {
        searchWindow.classList.add('active')
        scrollBlock.classList.add('block')
        setTimeout(document.getElementById("mk-fullscreen-search-input").focus(), 1000)
      }
      let close = document.querySelector('.fw-search>.close-btn')
      if (close) {
        close.addEventListener('click', function () {
          searchWindow.classList.remove('active')
          scrollBlock.classList.remove('block')
        })
      }
    })
  }

  $('.menu-item-has-children').on('click', function (e) {
    if ($(window).width() < 768 ) {
      if (!$(this).hasClass('sub-menu-opened')) {
        e.preventDefault();
        $(this).addClass('sub-menu-opened').trigger('hover')
      }
    }
  })

})(jQuery);